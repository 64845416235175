import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function SettingModal({
  open,
  onClose,
  printerList,
  paperSizes,
  updatePageSizes,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>打印设置</DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2} sx={{ mt: 1 }}>
          {paperSizes?.map((item) => (
            <Grid key={item.size} container item xs={12} alignItems="center">
              <Typography sx={{ width: 100, ml: 2 }}>{item.size}</Typography>
              <TextField
                sx={{ width: 300 }}
                select
                label="打印机"
                value={item.printer}
                onChange={(e) => {
                  if (e.target.value && e.type === "click") {
                    updatePageSizes(item.size, e.target.value);
                  }
                }}
              >
                {printerList.map((printer) => (
                  <MenuItem key={printer.name} value={printer.name}>
                    {printer.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
}
