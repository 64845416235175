import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import Login from "./page/Login";
import PrintPage from "./page/PrintPage";
import logo from "./holartec_print_logo.png";

export default function App() {
  const isLogin = localStorage.getItem("accessToken");
  // const [isLogin, setIsLogin] = useState(token ? true : false);
  return (
    <Container
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
      }}
    >
      <Box component="img" src={logo} sx={{ position: "relative" }} />
      {/* <Typography variant="h4" textAlign="center">
        HOLARTECT
      </Typography> */}
      <Typography variant="h4" textAlign="center">
        打印中心
      </Typography>
      {isLogin ? <PrintPage /> : <Login />}
      {isLogin && (
        <>
          <Button
            variant="outlined"
            color="error"
            sx={{ position: "fixed", top: 50, right: 50 }}
            onClick={() => {
              const DNS = localStorage.getItem("DNS");
              localStorage.clear();
              localStorage.setItem("DNS", DNS);
              window.location.reload();
            }}
          >
            登出
          </Button>
        </>
      )}
    </Container>
  );
}
