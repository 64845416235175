import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { axios_Prom } from "../util/api/api";
import ReceiptTemplate80mm from "./ReceiptTemplate80mm";
import ReceiptTemplateA4A5 from "./ReceiptTemplateA4A5";
import SettingModal from "./SettingModal";
const populateObjs = [
  { path: "Client", select: "nome code" },
  {
    path: "OrderProds",
    select:
      "code Prod OrderSkus nome price_sale is_simple quantity price_regular  price prod_quantity unit",
    populate: [
      {
        path: "OrderSkus",
        select: "Sku attrs price_sale quantity price_cost price",
      },
      {
        path: "Prod",
        select: "code nome nomeTR is_simple img_urls price_sale",
      },
    ],
  },
  { path: "Paidtype", select: "code nome" },
];

const testOrder = {
  is_hide_client: false,
  is_payAfter: false,
  is_ship: false,
  goods_quantity: 2,
  goods_regular: 39.5,
  goods_sale: 39.5,
  goods_price: 39.5,
  is_tax: false,
  ship_regular: 0,
  ship_discount: 0,
  ship_sale: 0,
  order_regular: 39.5,
  order_sale: 39.5,
  rate: 1,
  is_pass: true,
  isPaid: true,
  price_paid: 50,
  price_noPay: 0,
  is_paid: true,
  OrderProds: [
    {
      OrderSkus: [],
      quantity: 1,
      prod_quantity: 1,
      _id: "62a605bc28b3d758bbd640ac",
      Prod: {
        img_urls: [
          "/upload/Prod/623464c2bb68c1dd811a24b7-0-1653403033212.jpeg",
        ],
        _id: "62346499bb68c1dd811a0ceb",
        code: "3605975038132",
        nomeTR: "KIEHLS RARE EARTH DEEP PORE CLEANSING MASK 142G",
        nome: "科颜氏亚马逊白泥净致面膜",
        price_sale: 29.5,
        is_simple: true,
      },
      price: 29.5,
      is_simple: true,
      nome: "科颜氏亚马逊白泥净致面膜",
      unit: "盒",
      price_regular: 29.5,
      price_sale: 29.5,
    },
    {
      OrderSkus: [],
      quantity: 1,
      prod_quantity: 1,
      _id: "62a605bc28b3d758bbd640af",
      Prod: {
        img_urls: [
          "/upload/Prod/623464c2bb68c1dd811a24b7-0-1654859649776.jpeg",
        ],
        _id: "62346498bb68c1dd811a0ba1",
        code: "3282770037364",
        nomeTR: "AVENE EAU THERMALE LOZIONE MICELLARE STRUCCANTE 400ML",
        nome: "雅漾舒润卸妆水",
        price_sale: 10,
        is_simple: true,
      },
      price: 10,
      is_simple: true,
      nome: "雅漾舒润卸妆水",
      unit: "瓶",
      price_regular: 10,
      price_sale: 10,
    },
  ],
  _id: "62a605bc28b3d758bbd640a9",
  type_ship: 0,
  Paidtype: {
    _id: "6234656dbb68c1dd811a24d5",
    code: "CASH",
  },
  price_coin: 50,
  Shop: {
    nome: "XD SOFIA MODA  兴达贸易",
    tel: "2284488886",
    addr: "via xxxx xx xxxx",
    zip: "20126 MILANO",
  },
  status: 100,
  is_defCoin: true,
  symbol: "€",
  code: "220612OMIL0003",
  at_crt: "2022-06-12T15:26:52.803Z",
  Firm: "61ca0348d01d830a0056049f",
  goods_weight: 0.76,
  at_confirm: "2022-06-12T15:26:52.803Z",
  type_Order: -1,
  Supplier: null,
  Client: null,
  order_imp: 50,
  at_upd: "2022-06-13T11:22:51.468Z",
  is_regular: false,
  is_sale: false,
  __v: 0,
};
const initPaperSizes = [
  { size: "80mm", printer: "" },
  { size: "A4", printer: "" },
  { size: "A5", printer: "" },
];

export default function PrintPage() {
  const [showSettingModal, setShowSettingModal] = useState(
    !localStorage.getItem("paperSizes")
  );
  const [curOrder, setCurOrder] = useState(null);
  const [startProcess, setStartProcess] = useState(false);
  const [printerList, setPrinterList] = useState([]);

  const [paperSizes, setPaperSizes] = useState(
    localStorage.getItem("paperSizes")
      ? JSON.parse(localStorage.getItem("paperSizes"))
      : initPaperSizes
  );

  const [selectedPrinterName, setSelectedPrinterName] = useState(
    localStorage.getItem("deviceName")
  );
  const [selectedPageSize, setSelectedPageSize] = useState("");

  console.log(printerList);
  console.log(selectedPrinterName);

  const subscribePrint = async () => {
    const printRes = await axios_Prom(
      `/printTicket?populateObjs=${JSON.stringify(populateObjs)}`
    );
    console.log("job", printRes);
    const job = printRes.data.object;
    const jobs = printRes.data?.count;
    if (job) {
      console.log("has jobs", jobs, job);
      setCurOrder(job.object);
      setSelectedPageSize(job.typePrint);
    }
    if (jobs > 1) {
      subscribePrint();
    } else {
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      setTimeout(() => {
        // setCurOrder(null);
        subscribePrint();
      }, 5000);
    }
  };

  const generatePrinterList = (list) => {
    let newList = [];
    list?.forEach((printer) => {
      //put default print index 0 & set default printer name
      if (printer.isDefault) {
        newList.unshift(printer);
        // setSelectedPrinterName(printer.name);
        // localStorage.setItem("deviceName", printer.name);
      } else newList.push(printer);
    });
    setPrinterList(newList);
  };

  useEffect(() => {
    startProcess && subscribePrint();
  }, [startProcess]);

  useEffect(() => {
    console.log("init");
    window.electron?.printApi?.getPrinterList(generatePrinterList);
  }, []);
  console.log(curOrder);
  // 628bceb4ac37b3365427eb5e

  const postPrint = async () => {
    const postPrintRes = await axios_Prom(
      `/addTicket/62a0b676920cb243004b7493?populateObjs=${JSON.stringify(
        populateObjs
      )}`
    );
    console.log("posted", postPrintRes);
  };
  console.log(
    paperSizes,
    selectedPageSize,
    paperSizes?.find((item) => item.size === selectedPageSize)?.printer
  );
  return (
    <Box sx={{ width: "50%", mt: 10 }}>
      <Stack spacing={5}>
        <Typography textAlign="center" variant="h5" sx={{ color: "#ffbf59" }}>
          打印功能已准备就绪
        </Typography>
        {startProcess ? (
          <>
            <Typography textAlign="center">
              {curOrder ? "打印中" : "任务接收中"}...
            </Typography>
            {curOrder ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <LinearProgress />
            )}
            <Button
              variant="outlined"
              color="warning"
              onClick={() => window.location.reload()}
            >
              停止接收
            </Button>
          </>
        ) : (
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                onClick={() => setShowSettingModal(true)}
              >
                打印机设置
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ width: "100%", height: 50, borderRadius: "20px" }}
                variant="contained"
                color="primary"
                onClick={() => setStartProcess(true)}
              >
                开始接收
              </Button>
            </Grid>
          </Grid>
        )}
      </Stack>

      {/* <Button onClick={postPrint}>添加打印</Button> */}

      <PrintComp
        pageSize={selectedPageSize}
        printer={
          paperSizes?.find((item) => item.size === selectedPageSize)?.printer ||
          printerList[0]?.name
        }
        allowPrint={startProcess}
        // order={testOrder}
        order={curOrder}
        resetCurOrder={() => setCurOrder(null)}
      />
      <SettingModal
        open={showSettingModal}
        onClose={() => setShowSettingModal(false)}
        printerList={printerList}
        paperSizes={paperSizes}
        updatePageSizes={(size, printer) =>
          setPaperSizes((prev) => {
            // const newPaperSizes = { ...prev, [size]: printer };
            const newPaperSizes = [...prev]?.map((item) => {
              if (item.size === size) item.printer = printer;
              return item;
            });
            localStorage.setItem("paperSizes", JSON.stringify(newPaperSizes));
            return newPaperSizes;
          })
        }
      />
    </Box>
  );
}

const PrintComp = ({ allowPrint, order, resetCurOrder, pageSize, printer }) => {
  const printJob = () => {
    console.log(111111, pageSize, printer, order);
    const deviceName = printer;
    window.electron?.printApi?.startPrint(
      deviceName,
      document.getElementById("print" + pageSize).innerHTML,
      ({ success, failureReason }) => {
        console.log({ success, failureReason });
        resetCurOrder();
        // success && resetCurOrder();
      }
    );
  };

  useEffect(() => {
    if (order?._id && allowPrint) printJob();
    // allowPrint && printJob();
  }, [order]);

  return (
    <div style={{ display: "none" }}>
      {/* <div> */}
      <ReceiptTemplate80mm order={order} />
      <ReceiptTemplateA4A5
        order={order}
        // pageSize={"A4"}
        pageSize={pageSize}
      />
    </div>
  );
};
