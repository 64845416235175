import React from "react";
import moment from "moment";
import getPrice from "../util/getPrice";
export default function ReceiptTemplate80mm({ order }) {
  const generateName = (name) => {
    if (typeof name === "string") {
      let newName = [];
      name
        .split(" ")
        .forEach((str) =>
          str === "" ? newName.push(<br />) : newName.push(<>{str}&nbsp;</>)
        );
      return newName;
    }
  };

  return (
    order && (
      <div id="print80mm">
        <div
          style={{
            width: "80mm",
            // border: "1px solid",
            fontSize: "14px",
            fontWeight: 600,
            boxSizing: "border-box",
            paddingBottom: "5mm",
            paddingTop: "5mm",
          }}
        >
          <div style={{ height: "5mm" }}></div>
          <div style={{ width: "70mm", margin: "auto" }}>
            <h2
              style={{ textAlign: "center", fontSize: "28px", marginBottom: 0 }}
            >
              {generateName(order.Shop?.nome)}
            </h2>
            <div style={{ textAlign: "center", borderBottom: "1px solid" }}>
              TEL. : {order.Shop?.tel}
            </div>
            <div style={{ height: "100px" }}>
              <div> {order.Shop?.addr}</div>
              <div>{order.Shop?.zip}</div>
            </div>
            <div>
              <div style={{ width: "50%", display: "inline-block" }}>
                CLIENTE:
              </div>
              <div
                style={{
                  width: "50%",
                  display: "inline-block",
                  textAlign: "end",
                }}
              ></div>
            </div>
            <div
              style={{
                height: "100px",
                border: "2px dashed",
                fontSize: "28px",
                fontWeight: "bold",
              }}
            >
              {order.Client?.nome}
            </div>

            <div style={{ margin: "5px 0" }}>
              Data: {moment(order.at_upd).format("YYYY-MM-DD")}
            </div>
            <div>No. {order.code}</div>

            <div
              style={{
                borderTop: "2px solid",
                margin: "5px 0",
              }}
            >
              <div style={{ width: "40%", display: "inline-block" }}>
                Codice / Nome
              </div>
              <div
                style={{
                  width: "20%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Q.tà
              </div>
              <div
                style={{
                  width: "20%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Prezzo
              </div>
              <div
                style={{
                  width: "20%",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                Totale
              </div>
            </div>
            {order.OrderProds?.map((op, index) => (
              <div
                key={index}
                style={{
                  marginTop: "5px",
                  borderTop: index !== 0 && "1px solid",
                }}
              >
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div
                    style={{
                      width: "90%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {op.Prod?.code}
                  </div>
                </div>
                <div
                  style={{
                    width: "20%",
                    display: "inline-block",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {op.quantity}
                </div>
                <div
                  style={{
                    width: "20%",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  {getPrice(op.price)}
                </div>
                <div
                  style={{
                    width: "20%",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  {getPrice(op.price * op.quantity)}
                </div>
                <div
                  style={{
                    width: "90%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  /{op.nome}
                </div>
              </div>
            ))}
            <div
              style={{
                margin: "5px 0",
                paddingTop: "5px",
                borderTop: "2px solid",
              }}
            >
              <span style={{ marginRight: "20px" }}>
                T.Art: {order.OrderProds?.length}
              </span>
              <span>Tot: {order.goods_quantity} pz</span>
            </div>
            <div
              style={{
                height: "40px",
                marginTop: "5px",
                border: "2px dashed",
                padding: "0 5px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  width: "50%",
                  fontSize: "14px",
                  display: "inline-block",
                  marginTop: "20px",
                }}
              >
                Totale da pagare:
              </div>
              <div
                style={{
                  width: "50%",
                  fontSize: "28px",
                  fontWeight: "bold",
                  textAlign: "right",
                  display: "inline-block",
                }}
              >
                {getPrice(order.order_imp)}
              </div>
            </div>
            <div
              style={{
                marginTop: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "47%",
                  height: "50px",
                  border: "2px dashed",
                  textAlign: "center",
                }}
              >
                Pagamento
              </div>
              <div
                style={{
                  width: "47%",
                  height: "50px",
                  border: "2px dashed",
                  textAlign: "center",
                }}
              >
                T
              </div>
            </div>
            <div>Nota:</div>
            <div style={{ border: "2px dashed", height: "100px" }}></div>
          </div>
          <div style={{ height: "5mm" }}></div>
        </div>
      </div>
    )
  );
}
