import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { axiosProm, fetch_Prom } from "../util/api/api";

export default function Login() {
  const DNS = localStorage.getItem("DNS");
  const [loginForm, setLoginForm] = useState({ DNS, code: "", pwd: "" });
  const [errMsg, setErrMsg] = useState("");
  const [showDNS, setShowDNS] = useState(false);
  const handleChange = (field) => (e) => {
    field && setLoginForm((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    loginForm.DNS && localStorage.setItem("DNS", loginForm.DNS);
    const loginRes = await axiosProm(
      "/login",
      "POST",
      {
        system: { code: loginForm.code, pwd: loginForm.pwd },
      },
      loginForm.DNS || DNS
    );
    console.log(loginRes);
    if (loginRes.status === 200) {
      if (loginRes.data?.payload?.role !== 100) {
        setErrMsg("此账号不是打印账号");
        return;
      }
      localStorage.setItem("accessToken", loginRes.data?.accessToken);
      localStorage.setItem("refreshToken", loginRes.data?.refreshToken);
      window.location.reload();
    } else setErrMsg(loginRes.message);
  };

  return (
    <Box component="form" onSubmit={handleLogin}>
      <Paper sx={{ p: 5, mt: 3, width: { xs: "80%", md: 350 } }} elevation={10}>
        <Stack spacing={3}>
          <Typography variant="h5" textAlign="center">
            请登录
          </Typography>
          <Typography variant="overline" color="error" textAlign="center">
            {errMsg}
          </Typography>
          {(!DNS || showDNS) && (
            <TextField
              required
              label="DNS"
              value={loginForm.DNS}
              onChange={handleChange("DNS")}
            />
          )}
          <TextField
            required
            label="账号"
            value={loginForm.code}
            onChange={handleChange("code")}
          />
          <TextField
            required
            type="password"
            label="密码"
            value={loginForm.pwd}
            onChange={handleChange("pwd")}
          />
          <Button variant="contained" type="submit">
            登录
          </Button>
        </Stack>
      </Paper>
      {DNS && (
        <Button
          onClick={() => setShowDNS(true)}
          variant="outlined"
          sx={{ position: "fixed", top: 50, right: 50 }}
        >
          DNS设置
        </Button>
      )}
    </Box>
  );
}
